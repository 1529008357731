var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-layout",
    { staticClass: "layout" },
    [
      _c(
        "a-layout-header",
        [
          _c(
            "a-row",
            {
              staticClass: "adress-header",
              attrs: { justify: "center", type: "flex" }
            },
            [
              _c(
                "a-col",
                [_c("a-icon", { attrs: { type: "clock-circle" } })],
                1
              ),
              _c("a-col", { staticClass: "mr-1" }, [
                _c("span", {
                  staticClass: "mr-0",
                  domProps: {
                    innerHTML: _vm._s(_vm.get(this, "accueil.horaires"))
                  }
                })
              ]),
              _c(
                "a-col",
                [
                  _c("a-icon", { attrs: { type: "environment" } }),
                  _vm._v(
                    " \n          " +
                      _vm._s(_vm.get(_vm.organisation, "adresse")) +
                      " \n      "
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-row",
            {
              staticClass: "header-menu white-bg my-1",
              attrs: { justify: "space-between", align: "middle", type: "flex" }
            },
            [
              _c(
                "a-col",
                {
                  attrs: {
                    xs: { span: 0, offset: 0 },
                    md: { span: 6, offset: 0 },
                    span: 10,
                    xl: 8
                  }
                },
                [
                  _c("img", {
                    staticClass: "logo",
                    attrs: { src: _vm.get(this, "accueil.logo_url") }
                  })
                ]
              ),
              _c(
                "a-col",
                { attrs: { md: 3, xs: { span: 11, offset: 7 } } },
                [
                  _c(
                    "a-button",
                    {
                      attrs: { type: "primary", icon: "login", size: "large" }
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "mr-1 white",
                          attrs: { href: "/adherent/login" }
                        },
                        [_vm._v(" Mon Compte")]
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-row",
            {
              staticClass: "header-menu aliceblue",
              attrs: { justify: "center", type: "flex" }
            },
            [
              _c(
                "a-col",
                { attrs: { md: 12, xs: 20 } },
                [
                  _c(
                    "a-menu",
                    {
                      staticClass: "aliceblue",
                      style: { lineHeight: "55px" },
                      attrs: {
                        selectedKeys: _vm.selectedKeys,
                        mode: "horizontal",
                        theme: "light"
                      }
                    },
                    [
                      _c("a-menu-item", { key: "1" }, [
                        _c("a", { attrs: { href: "/" } }, [_vm._v("Accueil")])
                      ]),
                      _vm.accueil && _vm.accueil.display_events
                        ? _c("a-menu-item", { key: "#events" }, [
                            _c("a", { attrs: { href: "#events" } }, [
                              _vm._v("Evenements")
                            ])
                          ])
                        : _vm._e(),
                      _vm.accueil && _vm.accueil.display_members
                        ? _c("a-menu-item", { key: "#members" }, [
                            _c("a", { attrs: { href: "#members" } }, [
                              _vm._v("Membres")
                            ])
                          ])
                        : _vm._e(),
                      _c("a-menu-item", { key: "#propos" }, [
                        _c("a", { attrs: { href: "#propos" } }, [
                          _vm._v("A Propos")
                        ])
                      ]),
                      _c("a-menu-item", { key: "/contact" }, [
                        _c("a", { attrs: { href: "#contact" } }, [
                          _vm._v("Contact")
                        ])
                      ]),
                      _vm.accueil && _vm.accueil.display_documents
                        ? _c("a-menu-item", { key: "/documents" }, [
                            _c("a", { attrs: { href: "#documents" } }, [
                              _vm._v("Documents")
                            ])
                          ])
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("a-layout-content", [
        _c(
          "div",
          {
            style: {
              background: "#fff",
              minHeight: "280px"
            }
          },
          [
            _c(
              "div",
              [
                _c(
                  "a-spin",
                  {
                    attrs: {
                      tip: "Chargement...",
                      size: "large",
                      spinning: _vm.loading
                    }
                  },
                  [
                    _c(
                      "a-row",
                      {
                        staticStyle: { height: "500px" },
                        style: {
                          background: "url(" + _vm.bg + ") ",
                          "background-size": "cover"
                        },
                        attrs: { align: "top", justify: "start", type: "flex" }
                      },
                      [
                        _c(
                          "a-col",
                          {
                            staticClass: "welcome",
                            attrs: {
                              md: { span: 11, offset: 1 },
                              xs: { span: 24, offset: 1 },
                              xxl: { span: 10, offset: 2 }
                            }
                          },
                          [
                            _c("h1", {
                              staticClass: "txt-start welcome-txt",
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.get(this, "accueil.message_accueil")
                                )
                              }
                            })
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm.accueil && _vm.accueil.display_events
                  ? _c(
                      "a-row",
                      {
                        staticClass: "mb aliceblue",
                        attrs: {
                          align: "middle",
                          id: "events",
                          justify: "center",
                          type: "flex"
                        }
                      },
                      [
                        _c("a-col", { attrs: { span: 24 } }, [
                          _c(
                            "h2",
                            {
                              staticClass: "capital txt-center mb",
                              staticStyle: { "margin-top": "20px" }
                            },
                            [
                              _vm._v(
                                " \n              EVENEMENTS \n            "
                              )
                            ]
                          )
                        ]),
                        _c(
                          "a-col",
                          { attrs: { span: 24 } },
                          [_c("Events", { attrs: { events: _vm.events } })],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "a-row",
                  {
                    staticClass: "whitebg",
                    attrs: { align: "middle", justify: "center", type: "flex" }
                  },
                  [
                    _c("a-col", { attrs: { span: 24 } }, [
                      _c(
                        "h2",
                        {
                          staticClass: "capital txt-center mb",
                          staticStyle: { "margin-top": "20px" }
                        },
                        [
                          _vm._v(
                            " \n              A PROPOS DE L'ORDRE \n            "
                          )
                        ]
                      )
                    ])
                  ],
                  1
                ),
                _c("APropos", {
                  attrs: { propos: _vm.get(this, "accueil.a_propos") }
                }),
                _c(
                  "a-row",
                  {
                    staticClass: "aliceblue",
                    attrs: { align: "middle", justify: "center", type: "flex" }
                  },
                  [
                    _c("a-col", { attrs: { span: 24 } }, [
                      _c(
                        "h2",
                        {
                          staticClass: "capital txt-center mb",
                          staticStyle: { "margin-top": "20px" }
                        },
                        [
                          _vm._v(
                            " \n              Mot du président \n            "
                          )
                        ]
                      )
                    ])
                  ],
                  1
                ),
                _c("mot-de-president", {
                  attrs: {
                    mot_de_president: _vm.get(this, "accueil.mot_de_president")
                  }
                }),
                _vm.accueil && _vm.accueil.display_members
                  ? _c(
                      "a-row",
                      {
                        staticClass: "white-bg",
                        attrs: {
                          align: "middle",
                          justify: "center",
                          type: "flex"
                        }
                      },
                      [
                        _c("a-col", { attrs: { span: 24 } }, [
                          _c(
                            "h2",
                            {
                              staticClass: "capital txt-center mb",
                              staticStyle: { "margin-top": "20px" }
                            },
                            [
                              _vm._v(
                                " \n              Les membres \n            "
                              )
                            ]
                          )
                        ])
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.accueil && _vm.accueil.display_members
                  ? _c("Members", { attrs: { membres: _vm.membres } })
                  : _vm._e(),
                _c(
                  "a-row",
                  {
                    staticClass: "mb aliceblue",
                    attrs: {
                      align: "middle",
                      id: "contact",
                      justify: "center",
                      type: "flex"
                    }
                  },
                  [
                    _vm.showContactForm
                      ? _c(
                          "a-col",
                          { attrs: { md: { span: 12 }, xs: { span: 18 } } },
                          [
                            _c("h2", { staticClass: "capital txt-center mb" }, [
                              _vm._v("Contact")
                            ]),
                            _c("Contact", {
                              ref: "contactForm",
                              on: {
                                "form-submitted": _vm.handleContactFormSubmited
                              }
                            })
                          ],
                          1
                        )
                      : _c(
                          "a-col",
                          { attrs: { md: { span: 12 }, xs: { span: 18 } } },
                          [_c("ContactSubmittedSuccessfully")],
                          1
                        )
                  ],
                  1
                ),
                _vm.accueil && _vm.accueil.display_documents
                  ? _c(
                      "a-row",
                      {
                        staticClass: "mb white-bg",
                        attrs: {
                          align: "middle",
                          id: "documents",
                          justify: "space-around",
                          type: "flex"
                        }
                      },
                      [
                        _c("a-col", { attrs: { span: 24 } }, [
                          _c(
                            "h2",
                            {
                              staticClass: "capital txt-center mb",
                              staticStyle: { "margin-top": "20px" }
                            },
                            [
                              _vm._v(
                                " \n              Revues scientifique \n            "
                              )
                            ]
                          )
                        ]),
                        _c(
                          "a-col",
                          { attrs: { span: 24 } },
                          [
                            _c("Documents", {
                              attrs: { docs: _vm.docsToDownload }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "a-row",
                  {
                    staticClass: "mb aliceblue",
                    attrs: {
                      align: "middle",
                      justify: "space-around",
                      type: "flex"
                    }
                  },
                  [
                    _c(
                      "a-col",
                      {
                        attrs: { md: { span: 10, offset: 1 }, xs: { span: 22 } }
                      },
                      [
                        _c("Adress", {
                          attrs: { organisation: _vm.organisation }
                        })
                      ],
                      1
                    ),
                    _c(
                      "a-col",
                      { attrs: { md: { span: 10 }, xs: { span: 22 } } },
                      [
                        _vm.displayMap
                          ? _c("MyMap", {
                              attrs: { coordinates: _vm.coordinates }
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
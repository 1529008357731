<template>
        <a-layout class="layout"> 
        <a-layout-header> 
          <a-row class="adress-header" justify="center" type="flex"> 
            <a-col> 
              <a-icon type="clock-circle" /> 
            </a-col> 
            <a-col class="mr-1"> 
                <span class="mr-0" v-html="get(this, 'accueil.horaires')"> </span> 
            </a-col> 
            <a-col> 
              <a-icon type="environment" /> 
                {{ get(organisation, "adresse") }} 
            </a-col> 
          </a-row> 
          <a-row 
            class="header-menu white-bg my-1" 
            justify="space-between" 
            align="middle" 
            type="flex" 
          > 
            <a-col 
              :xs="{ span: 0, offset: 0 }" 
              :md="{ span: 6, offset: 0 }" 
              :span="10" 
              :xl="8" 
            > 
              <img :src="get(this, 'accueil.logo_url')" class="logo" /> 
            </a-col> 
            <a-col :md="3" :xs="{ span: 11, offset: 7 }"> 
              <a-button type="primary" icon="login" size="large"> 
                 <a href="/adherent/login" class="mr-1 white"> Mon Compte</a> 
              </a-button> 
            </a-col> 
          </a-row> 
          <a-row class="header-menu aliceblue" justify="center" type="flex"> 
            <a-col :md="12" :xs="20"> 
              <a-menu 
                :selectedKeys="selectedKeys" 
                :style="{ lineHeight: '55px' }" 
                class="aliceblue" 
                mode="horizontal" 
                theme="light" 
              > 
                <a-menu-item key="1"><a href="/">Accueil</a></a-menu-item> 
                <a-menu-item key="#events" v-if="accueil && accueil.display_events"> 
                  <a href="#events">Evenements</a> 
                </a-menu-item> 
                <a-menu-item key="#members" v-if="accueil && accueil.display_members"> 
                  <a href="#members">Membres</a> 
                </a-menu-item> 
                <a-menu-item key="#propos"> 
                  <a href="#propos">A Propos</a> 
                </a-menu-item> 
                <a-menu-item key="/contact"> 
                  <a href="#contact">Contact</a> 
                </a-menu-item> 
                <a-menu-item key="/documents" v-if="accueil && accueil.display_documents" > 
                  <a href="#documents">Documents</a> 
                </a-menu-item> 
              </a-menu> 
            </a-col> 
          </a-row> 
        </a-layout-header> 
        <a-layout-content> 
          <div 
            :style="{ 
              background: '#fff', 
              minHeight: '280px', 
            }" 
          > 
            <div> 
              <a-spin tip="Chargement..." size="large" :spinning="loading"> 
                <a-row 
                  :style="{ 
                    background: 'url(' + bg + ') ', 
                    'background-size': 'cover', 
                  }" 
                  align="top" 
                  justify="start" 
                  style="height: 500px" 
                  type="flex" 
                > 
                  <a-col 
                    :md="{ span: 11, offset: 1 }" 
                    :xs="{ span: 24, offset: 1 }" 
                    :xxl="{ span: 10, offset: 2 }" 
                    class="welcome" 
                  > 
                    <h1 
                      class="txt-start welcome-txt" 
                      v-html="get(this, 'accueil.message_accueil')" 
                    ></h1> 
                  </a-col> 
                </a-row> 
              </a-spin> 
              <a-row 
                align="middle" 
                id="events" 
                class="mb aliceblue" 
                justify="center" 
                type="flex" 
                v-if="accueil && accueil.display_events"
              > 
                <a-col :span="24"> 
                  <h2 style="margin-top: 20px" class="capital txt-center mb"> 
                    EVENEMENTS 
                  </h2> 
                </a-col> 
                <a-col :span="24"> 
                  <Events :events="events" /> 
                </a-col> 
              </a-row> 
              <a-row align="middle" justify="center" class="whitebg" type="flex"> 
                <a-col :span="24"> 
                  <h2 style="margin-top: 20px" class="capital txt-center mb"> 
                    A PROPOS DE L'ORDRE 
                  </h2> 
                </a-col> 
              </a-row> 
              <APropos :propos="get(this, 'accueil.a_propos')" /> 

              <a-row align="middle" justify="center" class="aliceblue" type="flex"> 
                <a-col :span="24"> 
                  <h2 style="margin-top: 20px" class="capital txt-center mb"> 
                    Mot du président 
                  </h2> 
                </a-col> 
              </a-row>
              <mot-de-president :mot_de_president="get(this, 'accueil.mot_de_president')"/> 

              <a-row v-if="accueil && accueil.display_members" align="middle" justify="center" class="white-bg" type="flex"> 
                <a-col :span="24"> 
                  <h2 style="margin-top: 20px" class="capital txt-center mb"> 
                    Les membres 
                  </h2> 
                </a-col> 
              </a-row> 
              <Members v-if="accueil && accueil.display_members" :membres="membres" /> 
              <a-row 
                align="middle" 
                id="contact" 
                class="mb aliceblue" 
                justify="center" 
                type="flex" 
              > 
                <a-col v-if="showContactForm" :md="{ span: 12 }" :xs="{ span: 18 }"> 
                  <h2 class="capital txt-center mb">Contact</h2> 
                  <Contact 
                    ref="contactForm" 
                    @form-submitted="handleContactFormSubmited" 
                  /> 
                </a-col> 
                <a-col v-else :md="{ span: 12 }" :xs="{ span: 18 }"> 
                  <ContactSubmittedSuccessfully /> 
                </a-col> 
              </a-row> 
              <a-row 
                align="middle" 
                id="documents" 
                class="mb white-bg" 
                justify="space-around" 
                type="flex" 
                v-if="accueil && accueil.display_documents"
              > 
                <a-col :span="24"> 
                  <h2 style="margin-top: 20px" class="capital txt-center mb"> 
                    Revues scientifique 
                  </h2> 
                </a-col> 
                <a-col :span="24"> 
                  <Documents :docs="docsToDownload" /> 
                </a-col> 
              </a-row> 
              <a-row 
                align="middle" 
                class="mb aliceblue" 
                justify="space-around" 
                type="flex" 
              > 
                <a-col :md="{ span: 10, offset: 1 }" :xs="{ span: 22 }"> 
                  <Adress :organisation="organisation" /> 
                </a-col> 
                <a-col :md="{ span: 10 }" :xs="{ span: 22 }"> 
                  <MyMap v-if="displayMap" :coordinates="coordinates" /> 
                </a-col> 
              </a-row> 
            </div> 
          </div> 
        </a-layout-content> 
      </a-layout>

</template>

<script>

// const SHOW_EVENT = 2;
const SHOW_MEMBER = 2;

import bg from "@/assets/index/bg.jpg";
import {mapActions, mapState} from "vuex";

const MyMap = () => import("@/components/common/Map");
const Events = () => import("./Events");
const Members = () => import("./Members");
const Contact = () => import("./Contact");
const Documents = () => import("./DocsToDownload");
const APropos = () => import("./APropos");
const Adress = () => import("./Adress");
const ContactSubmittedSuccessfully = () => import("./ContactSubmittedSuccessfully");
const MotDePresident = () => import("./MotDePresident");

export default {
  components: {
    MyMap,
    Events,
    Members,
    Contact,
    Documents,
    APropos,
    Adress,
    ContactSubmittedSuccessfully,
    MotDePresident
  },

  data() {
    const selectedKeys = ["1"];
    return {
      selectedKeys,
      primary: "#493d55",
      secondary: "#845579",
      bg,
      spinning: false,
      loading: true,
      showMapTimeoutPassed: false,
      showContactForm: true,
    };
  },
  created() {
    this.fetchAccueil().then(() => (this.loading = false));
  },
  mounted() {
    setTimeout(() => (this.showMapTimeoutPassed = true), 5000);
  },
  computed: {
    displayMap() {
      return this.showMapTimeoutPassed && this.coordinates[0];
    },
    coordinates() {
      return [
        this.get(this, "organisation.longitude", null),
        this.get(this, "organisation.latitude", null),
      ];
    },
 
    ...mapState({
      accueil: (state) => state.accueil.index_page.accueil,
      docsToDownload: (state) => state.accueil.index_page.documents,
      organisation: (state) => state.accueil.index_page.organisation,
      membres: (state) => state.accueil.index_page.membres,
      events: (state) => state.accueil.index_page.events,
    }),
  },
  methods: {
    showMember(membre) {
      return SHOW_MEMBER === membre.etat;
    },
    hideContactForm() {
      this.showContactForm = false;
    },
    handleContactFormSubmited() {
      this.hideContactForm();
    },
    ...mapActions({
      fetchAccueil: "fetchIndexPage",
    }),
  },
};
</script>
<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Sora:wght@200&display=swap");

.image {
  background-color: #cccccc;
}

.txt-center {
  text-align: center;
}

.mr-0 {
  margin: 0 !important;
}

p {
  margin-bottom: 0 !important;
}

.login {
  background-color: #1aa391;
  color: white;
  border-bottom: 2px solid #1aa391;
  padding: 10px 12px;
}

.txt-start {
  text-align: start;
}

.contact-title {
  margin-left: 5%;
}

.txt-left {
  text-align: left;
}

.white {
  color: white;
}

.white-bg {
  background-color: white;
}

.ant-timeline.ant-timeline-alternate
  .ant-timeline-item-right
  .ant-timeline-item-content,
.ant-timeline.ant-timeline-right
  .ant-timeline-item-right
  .ant-timeline-item-content {
  text-align: left;
}

li {
  text-align: left;
}

.large-text {
  font-size: large;
}

.header-menu {
  padding: 0 10px 0 10px;
}

.ant-card-meta-title {
  color: #493d55;
}

.bg-img {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  width: auto;
}
.bgimg::before {
  content: "";
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 35%,
    rgba(255, 255, 255, 0.3925945378151261) 100%
  );
  opacity: 0.8;
}
.welcome {
  padding: 1%;
  font-size: 1rem;
  margin-top: 3%;
  color: white;
  padding-left: 0%;
}

.mb {
  padding-top: 10px;
  padding-bottom: 10px;
}

.pr-5 {
  padding-right: 5%;
}

.pl-5 {
  padding-left: 5%;
}

.opacity {
  filter: alpha(opacity=30);

  -moz-opacity: 0.3;

  -khtml-opacity: 0.3;

  opacity: 0.3;
}

.welcome-txt {
  line-height: 1.6;
  font-size: 38px;
  font-weight: bold;
}

.site-layout-content {
  min-height: 280px;
  padding: 24px;
  background: #fff;
}

.logo {
  float: left;
  width: 100%;
  height: 100%;
  max-width: 393px;
  max-height: 73px;
  margin-left: 10px;
}

.ant-row-rtl #components-layout-demo-top .logo {
  float: right;
  margin: 16px 0 16px 24px;
}

[data-theme="dark"] .site-layout-content {
  background: #141414;
}

.ant-layout-header {
  padding: 0 !important;
  line-height: inherit !important;
  height: inherit;
  background: white;
}

.capital {
  text-transform: uppercase;
}

.ant-menu-horizontal > .ant-menu-item:hover,
.ant-menu-horizontal > .ant-menu-submenu:hover,
.ant-menu-horizontal > .ant-menu-item-active,
.ant-menu-horizontal > .ant-menu-submenu-active,
.ant-menu-horizontal > .ant-menu-item-open,
.ant-menu-horizontal > .ant-menu-submenu-open,
.ant-menu-horizontal > .ant-menu-item-selected,
.ant-menu-horizontal > .ant-menu-submenu-selected {
  color: #52c41a;
  border-bottom: 2px solid #52c41a;
}

.ant-menu {
  font-weight: 600;
}

.green {
  color: #52c41a;
}

.black {
  color: black;
}

.horaire {
  border-color: #dd0606;
  padding-left: 1%;
  border-style: none none none solid;
  color: #dd0606;
}

.horaire_title {
  font-weight: 600;
  color: #dd0606;
}

.horaire_text {
  font-weight: 500;
  color: #845579;
}

.horaire_time {
  color: #493d55;
  font-size: larger;
}

.black {
  color: black;
}

.tiemeline-number {
  font-size: 22px;
  background-color: #ff8514;
}

h1,
h2,
h3,
h4,
h5,
h6,
.my-blue {
  font-family: Sora, ubuntu, monospace;
  font-weight: 600;
  color: rgb(31 54 92);
}

.ant-layout {
  background-color: white;
}

.ant-menu-item,
.ant-menu-submenu-title {
  padding: 0 3%;
}

.ant-menu-horizontal > .ant-menu-item:hover,
.ant-menu-horizontal > .ant-menu-submenu:hover,
.ant-menu-horizontal > .ant-menu-item-active,
.ant-menu-horizontal > .ant-menu-submenu-active,
.ant-menu-horizontal > .ant-menu-item-open,
.ant-menu-horizontal > .ant-menu-submenu-open,
.ant-menu-horizontal > .ant-menu-item-selected,
.ant-menu-horizontal > .ant-menu-submenu-selected {
  color: #ff8514;
  border-bottom: 2px solid #1aa391;
}

.adress {
  margin-bottom: 0px;
  margin: 1px 0;
}

.adress-header {
  background-color: #1f365c;
  color: white !important;
  max-height: 22px;
}
/* For demo */
.ant-carousel >>> .slick-dots {
  height: auto;
}

.ant-carousel >>> .slick-slide img {
  border: 5px solid #fff;
  display: block;
  margin: auto;
  max-width: 80%;
}

.ant-carousel >>> .slick-thumb {
  bottom: -45px;
}

.ant-carousel >>> .slick-thumb li {
  width: 60px;
  height: 45px;
}

.ant-carousel >>> .slick-thumb li img {
  width: 100%;
  height: 100%;
  filter: grayscale(100%);
}

.ant-carousel >>> .slick-thumb li.slick-active img {
  filter: grayscale(0%);
}

.mb-2 {
  margin-bottom: 4%;
}

#contact {
  padding-top: 2%;
}
.aliceblue {
  background: aliceblue;
}
body {
  font-family: "Sora", sans-serif;
}

.mt-1 {
  margin-top: 10px;
}

.mr-1 {
  margin-right: 1%;
}
.my-1 {
  margin: 5px 0;
}
.user_icon {
  width: 100px;
  height: 100px;
  line-height: 70px;
  font-size: 64px;
}
</style>

